import React from "react";
import "./rodape.css";

export default function Rodape(props) {
  return (
    <footer className="conteudoRodape">
      <p>* Parcela mínima de R$ 30,00</p>
    </footer>
  );
}
