import React from 'react';
import TabelaProdutos from './components/tabela/tabelaProdutos';
import './App.css';

function App() {
  return (
    <TabelaProdutos />
  )   
}

export default App;
