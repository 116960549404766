import React from "react";
import Logo from "../../assets/images/Logo-Dr-Consulta-Branco.svg";
import "./cabecalho.css";

export default function Cabecalho(titulo) {
  return (
    <header>
      <div className="conteudoCabecalho">
        <div className="tituloCabecalho">{titulo?.titulo?.replace('Ultrassom', 'Ultrassonografia')}</div>
        <img src={Logo} alt="logo dr.consulta" />
      </div>
    </header>
  );
}
