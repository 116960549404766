import React, { useState, useEffect } from "react";
import Cabecalho from "../cabecalho/cabecalho";
import Rodape from "../rodape/rodape";
import api from "../../services/api";

import "./tabelaProdutos.css";

const TabelaProdutos = () => {
  const [titulo, setTitulo] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [loader, setLoader] = useState(false);

  const separarGrupos = (base, max) => {
    let resultado = [[]];
    let grupo = 0;

    for (let indice = 0; indice < base.length; indice++) {
      if (resultado[grupo] === undefined) {
        resultado[grupo] = [];
      }

      resultado[grupo].push(base[indice]);

      if ((indice + 1) % max === 0) {
        grupo = grupo + 1;
      }
    }

    return resultado;
  };

  const renderizarProdutos = (produtos) => {
    let grupoProdutos = [];

    const titulosGrupos = Object.keys(produtos);

    titulosGrupos.map((tituloGrupo) => {
      let produtosExibidosTela = separarGrupos(produtos[tituloGrupo], 5);

      let produtosPorGrupo = {
        titulo: tituloGrupo,
        produtos: produtosExibidosTela,
      };

      return grupoProdutos.push(produtosPorGrupo);
    });

    return grupoProdutos;
  };

  const Slider = (tituloGrupo) => {
    let slider = document.querySelector(".containerProdutos");
    let sliderIndividual = document.querySelectorAll(".containerSlider");
    // para iniciar a partir do segundo slide
    let contador = 1;
    let largura = sliderIndividual[0].clientWidth;
    let interval = 30000;

    const titulo = tituloGrupo;

    window.addEventListener("resize", function () {
      largura = sliderIndividual[0].clientWidth;
    });

    setTitulo(titulo[0]);

    const progressoSlider = () => {
      setTitulo(
        titulo[sliderIndividual[contador].querySelector("div").innerHTML]
      );
      Slides();
    };

    setInterval(progressoSlider, interval);

    function Slides() {
      slider.style.transform = "translate(" + -largura * contador + "px)";
      slider.style.transition = "transform 0s";
      contador++;

      if (contador === sliderIndividual.length) {
        slider.style.transform = "translate(0px)";
        slider.style.transition = "transform 0s";
        contador = 1;
        setTitulo(titulo[0]);
      }
    }
  };

  const obterProdutos = async () => {
    const parametros = new URLSearchParams(
      document.location.search.substring(1)
    );
    const Uf = parametros.get("uf");
    const Sigla = parametros.get("sigla");

    await api
      .get(`product-menu/?uf=${Uf}&sigla=${Sigla}`)
      .then((resposta) => {
        if (resposta.data) {
          setLoader(true);
          const grupoProduto = Object.keys(resposta.data);

          for (let i = 0; i < grupoProduto.length; i++) {
            resposta.data[grupoProduto[i]] = resposta.data[
              grupoProduto[i]
            ].filter((produto) => produto.preco > 0);
          }

          setProdutos(resposta.data);
          Slider(Object.keys(resposta.data));
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    obterProdutos();
  }, []);

  const renderizarDesconto = (precoProduto, precoProdutoCartaoDrc) => {
    const valorComCartao = precoProdutoCartaoDrc
      ? Number(precoProdutoCartaoDrc)
      : null;
    const valorSemCartao = Number(precoProduto);

    if (valorComCartao === valorSemCartao || valorComCartao === null) {
      return (
        <div className="economiaContainer borderContainer">
          <p className="naoCoberto naoCobertoAzul">Não coberto</p>
        </div>
      );
    }

    const economia = valorSemCartao - valorComCartao;

    const economiaString = economia.toFixed(2).toString().replace(".", ",");
    const economiaSemDecimais = economiaString.slice(0, -3);
    const economiaCasaDecimais = economiaString.slice(-3);
    return (
      <div className="economiaContainer borderContainer">
        <div className="formatacaoPrecoContainer">
          <span className="cifraoEconomia"> R$</span>
          <span className="valorEconomiaTexto">{economiaSemDecimais}</span>
          <span className="casasDecimaisEconomia">{economiaCasaDecimais}</span>
        </div>
      </div>
    );
  };
  const renderizarPrecoSemCartao = (
    precoProduto,
    qtdParcelas,
    valorParcelas
  ) => {
    let elementoASerRenderizado = <></>;
    const valorComCartaoString = Number(precoProduto)
      .toFixed(2)
      .toString()
      .replace(".", ",");

    const valorComCartaoSemDecimais = valorComCartaoString.slice(0, -3);
    const valorComCartaoCasasDecimais = valorComCartaoString.slice(-3);

    const precoCartaoDrcContainer = (
      <div className="precoSemCartaoDrcContent">
        <span className="cifraoSemCartaoDrc"> R$</span>
        <span className="valorSemCartaoDrc">{valorComCartaoSemDecimais}</span>
        <span className="casasDecimaisSemCartaoDrc">
          {valorComCartaoCasasDecimais}
        </span>
      </div>
    );
    const parcelasSemCartaoDrcContainer = (
      <div className="parcelasPrecoSemCartaoDrcContainer ">
        <span className="emAtePrecoSemCartaoDrc">em até </span>
        <span className="parcelasPrecoSemCartaoDrc">
          {qtdParcelas}x de R$ {valorParcelas}{" "}
        </span>
      </div>
    );

    elementoASerRenderizado = (
      <div className="precoSemCartaoDrcItemsContainer">
        {precoCartaoDrcContainer}
        {parcelasSemCartaoDrcContainer}
      </div>
    );

    return (
      <div className="precoSemComCartaoContainer borderContainer">
        {elementoASerRenderizado}
      </div>
    );
  };

  const renderizarPrecoComCartao = (
    precoProduto,
    precoCartaoDrc,
    qtdParcelasCartaoDrc,
    valorParcelasCartaoDrc
  ) => {
    let elementoASerRenderizado = <></>;
    if (
      precoCartaoDrc === null ||
      Number(precoCartaoDrc) === Number(precoProduto)
    ) {
      elementoASerRenderizado = (
        <div className="naoCoberto naoCobertoBranco">Não coberto</div>
      );
    } else if (Number(precoCartaoDrc) === 0) {
      elementoASerRenderizado = <div className="incluso">Incluso no plano</div>;
    } else {
      const valorComCartaoString = Number(precoCartaoDrc)
        .toFixed(2)
        .toString()
        .replace(".", ",");

      const valorComCartaoSemDecimais = valorComCartaoString.slice(0, -3);
      const valorComCartaoCasasDecimais = valorComCartaoString.slice(-3);

      const precoCartaoDrcContainer = (
        <div className="precoCartaoDrcContent">
          <span className="cifraoCartaoDrc"> R$</span>
          <span className="valorCartaoDrc">{valorComCartaoSemDecimais}</span>
          <span className="casasDecimaisCartaoDrc">
            {valorComCartaoCasasDecimais}
          </span>
        </div>
      );
      const parcelasCartaoDrcContainer = (
        <div className="parcelasPrecoCartaoDrcContainer ">
          <span className="emAtePrecoCartaoDrc">em até </span>
          <span className="parcelasPrecoCartaoDrc">
            {qtdParcelasCartaoDrc}x de R$ {valorParcelasCartaoDrc}{" "}
          </span>
        </div>
      );

      elementoASerRenderizado = (
        <div className="precoComCartaoDrcItemsContainer">
          {precoCartaoDrcContainer}
          {parcelasCartaoDrcContainer}
        </div>
      );
    }

    return (
      <div className="precoComCartaoContainer borderContainer">
        {elementoASerRenderizado}
      </div>
    );
  };

  const formatarNomeProduto = (nomeProduto) => {
    const nomeProdutoFormatado = nomeProduto
      .toLowerCase()
      .replace("usg", "ultrassonografia");

    const nomeProdutoCapitalizado =
      nomeProdutoFormatado.charAt(0).toUpperCase() +
      nomeProdutoFormatado.slice(1);

    if (nomeProdutoCapitalizado.length > 57) {
      return nomeProdutoCapitalizado.substring(0, 57) + "...";
    }

    return nomeProdutoCapitalizado;
  };

  return (
    loader && (
      <>
        <Cabecalho titulo={titulo} />
        <div className="container">
          <div className="containerTituloPlanos">
            <p className="tituloPlano tituloPrecoSemCartaoDrc">
              <span>sem Cartão dr.consulta</span>
            </p>
            <p className="tituloPlano tituloPrecoCartaoDrc">
              <span>com Cartão dr.consulta</span>
            </p>
            <p className="tituloPlano tituloValorEconomia">
              <span>Economia</span>
            </p>
          </div>
          <div className="containerProdutos">
            {renderizarProdutos(produtos).map((grupoProduto, indiceGrupo) => {
              return grupoProduto.produtos.map(
                (produtoTela, indiceProdutoTela) => {
                  return (
                    <section
                      className="containerSlider"
                      key={
                        grupoProduto.titulo + indiceGrupo + indiceProdutoTela
                      }
                    >
                      <div style={{ display: "none" }}>{indiceGrupo}</div>
                      {produtoTela.map((produto, indice) => (
                        <div
                          className="produtos"
                          key={produto.produto + indice}
                        >
                          <div className="conteudoProdutos">
                            <div className="colunas">
                              <h2 className="tituloProduto">
                                {formatarNomeProduto(produto.produto)}
                              </h2>
                            </div>
                            <div className="colunas">
                              <div className="containerPreco preco">
                                {renderizarPrecoSemCartao(
                                  produto.preco,
                                  produto.num_parcela,
                                  produto.valor_parcela
                                )}
                              </div>
                            </div>
                            <div className="colunas">
                              <div className="containerPreco precoCartaoDrc">
                                {renderizarPrecoComCartao(
                                  produto.preco,
                                  produto.preco_cartao_drc,
                                  produto.num_parcela_cartao_drc,
                                  produto.valor_parcela_cartao_drc
                                )}
                              </div>
                            </div>
                            <div className="colunas">
                              <div className="containerPreco valorEconomia">
                                {renderizarDesconto(
                                  produto.preco,
                                  produto.preco_cartao_drc
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <Rodape />
                    </section>
                  );
                }
              );
            })}
          </div>
        </div>
      </>
    )
  );
};

export default TabelaProdutos;
